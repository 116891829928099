import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import memoize from 'lodash/memoize'

import { _ } from 'Services/I18n'

import { withAppContext } from 'Services/Context'
import { PUBLIC_PATHS } from 'Constants/paths'
import Labels from 'Constants/labels'
import { MARKETPLACE_SERVICE_TYPE } from 'Constants/ids'

import {
  MerchantsSearch,
  FeaturedCategories,
  FeaturedLocations,
  BannerTabs,
  IFrame,
} from 'Components/Blocks'

import { Box, Loader } from 'Components/UI'

import { loadAllCategories } from 'Store/Actions/marketplace'

import {
  processBucketForSearchPathParams,
  processBucketForAllResultsSearchPathParams,
} from 'Services/Utils/merchants'

import {
  Container,
  Content,
  Responsive,
  ResponsiveContent,
  Banner,
  SearchContainer,
  BannerTitleHolder,
  BannerTitleContent,
  BannerTitleBg,
  BannerTitle,
  FeaturedMerchantsContainer,
  FeaturedMerchantsTitle,
  ButtonContainer,
  ExploreButton,
  SubBanner,
  SubBannerTitle,
  SubBannerText,
  SubBannerContainer,
  SubBannerImage,
  SubBannerImageHolder,
  LoaderHolder,
  BannerTabsHolder,
} from './styles'
import TopOffersSliders from './TopOffersSliders'
import FeaturedMerchantsSliders from './FeaturedMerchantsSliders'

const sliderSettings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '50px',
  slidesToShow: 1,
  speed: 500,
  lazyLoad: 'ondemand',
}

const initialState = {
  topOffers: [],
  featuredBusinesses: [],
  featuredMerchants: [],
  featuredCategories: [],
  popularCategories: [],
  popularSearches: [],
  showSliders: false,
}

class Home extends PureComponent {
  static fetchData({ dispatch }) {
    return dispatch(loadAllCategories({ ssr: true }))
  }

  state = initialState

  static getDerivedStateFromProps(props, state) {
    const nextFeaturedMerchants = get(props, 'featuredMerchants', [])
    const prevFeaturedMerchants = get(state, 'featuredMerchants', [])
    const nextFeaturedCategories = get(props, 'featuredCategories', [])
    const prevFeaturedCategories = get(state, 'featuredCategories', [])

    const nextState = {}

    if (!isEqual(nextFeaturedMerchants, prevFeaturedMerchants)) {
      const { featuredBusinesses, topOffers } = reduce(
        nextFeaturedMerchants,
        (acc, feature) => {
          const promotionName = get(feature, 'promotion.name', null)

          if (promotionName) {
            acc.topOffers.push({ ...feature, name: promotionName })
          } else {
            acc.featuredBusinesses.push(feature)
          }

          return acc
        },
        {
          featuredBusinesses: [],
          topOffers: [],
        },
      )

      nextState.topOffers = topOffers
      nextState.featuredBusinesses = featuredBusinesses
      nextState.featuredMerchants = nextFeaturedMerchants
    }

    if (!isEqual(nextFeaturedCategories, prevFeaturedCategories)) {
      const { popularCategories, popularSearches } = reduce(
        nextFeaturedCategories,
        (acc, feature) => {
          const bucketType = get(feature, 'bucket_type', null)
          const supplementary = get(feature, 'supplementary', null)

          if (supplementary && bucketType === 'category_2') {
            acc.popularSearches.push(feature)
          } else {
            acc.popularCategories.push(feature)
          }

          return acc
        },
        {
          popularCategories: [],
          popularSearches: [],
        },
      )

      nextState.popularCategories = popularCategories
      nextState.popularSearches = popularSearches
      nextState.featuredCategories = nextFeaturedCategories
    }

    return isEmpty(nextState) ? null : nextState
  }

  componentDidMount() {
    // Fix rendering artifacts, because 'isPortableDevice' is not correct on SSR side
    this.setState({ showSliders: true })

    window.scrollTo({ top: 0 })
  }

  getSubBannerTitle = memoize(text => {
    try {
      return Buffer.from(text, 'base64').toString()
    } catch (error) {
      return text
    }
  })

  handleExploreMerchantsClick = () => {
    const { history } = this.props
    const params = processBucketForAllResultsSearchPathParams()

    history.push(PUBLIC_PATHS.SEARCH_QUERY(params))
  }

  handleNavigationFromMerchantsSearch = url => {
    const { history } = this.props
    history.push(url)
  }

  handleAllCategoriesClick = () => {
    const { history, onSetCategoryValue } = this.props
    onSetCategoryValue(null)
    history.push(PUBLIC_PATHS.CATEGORIES)
  }

  handleNavigateLocation = location => {
    const { history } = this.props

    const bucket = {
      bucket_id: location.id,
      bucket_name: location.region_name,
      bucket_slug: location.region_slug,
      full_name: `${location.region_name}, ${location.state_name}`,
    }

    const queryParams = {
      ...processBucketForSearchPathParams(
        { ...bucket, bucket_type: 'region' },
        1,
      ),
    }

    history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
  }

  getSectionTitle = (label, fallback) => {
    const { baseTheme } = this.props

    return get(baseTheme, `labels.${label}`, fallback)
  }

  renderBannerTitle = (banner, font) => {
    const { baseTheme } = this.props
    const splittedBanner = get(banner, 'copy', '')
      .replace(/<br\s*\/>/, ' <br/> ')
      .split(' ')

    const showHighlight = get(banner, 'copy_highlight', false)
    const background = showHighlight
      ? get(baseTheme, 'colors.secondary_background')
      : 'none'

    return map(splittedBanner, (symbol, index) => {
      if (symbol.trim() === '<br/>') {
        return (
          <BannerTitleContent
            background={background}
            height="4px"
            key={index}
            width="100%"
          >
            <br />
          </BannerTitleContent>
        )
      }

      return (
        <BannerTitleContent background={background} key={index}>
          <BannerTitle font={font}>
            <Box as="span" dangerouslySetInnerHTML={{ __html: symbol }} />
            {index === splittedBanner.length - 1 ? '' : <span>&nbsp;</span>}
          </BannerTitle>
          <BannerTitleBg background={background} />
        </BannerTitleContent>
      )
    })
  }

  render() {
    const {
      featuredBusinesses,
      topOffers,
      popularCategories,
      popularSearches,
      showSliders,
    } = this.state

    const {
      baseTheme,
      baseTheme: { banner, banner_2: subBanner },
      config,
      isFeaturedCategoriesLoading,
      featuredLocations,
      isFeaturedLocationsLoading,
      isFeaturedMerchantsLoading,
    } = this.props

    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0

    const bannerSize = get(banner, 'size') || 'full'

    const canShowFeaturedBusinesses =
      !isFeaturedMerchantsLoading && !isEmpty(featuredBusinesses)
    const canShowTopOffers = !isFeaturedMerchantsLoading && !isEmpty(topOffers)

    const canShowPopularCategories =
      !isFeaturedCategoriesLoading && !isEmpty(popularCategories)
    const canShowPopularLocations =
      !isFeaturedLocationsLoading && !isEmpty(featuredLocations)
    const canShowPopularSearches =
      !isFeaturedCategoriesLoading && !isEmpty(popularSearches)

    const secondaryColor = get(baseTheme, 'colors.secondary_background')
    const secondaryTextColor = get(baseTheme, 'colors.secondary_text')

    const primaryFont =
      get(baseTheme, 'colors.primary_font') || 'Inter, MarkPro, sans-serif'

    const bannerTabs = get(baseTheme, 'banner_tabs', [])

    const subBannerTitle = this.getSubBannerTitle(get(subBanner, 'copy', ''))

    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')
    const showAllCategoriesPage = get(baseTheme, 'show_all_categories_page')
    const iframeUrl = get(baseTheme, 'homepage_iframe_url')

    return (
      <Container>
        <Helmet>
          <meta content={get(banner, 'copy')} property="og:title" />
          <meta content={get(subBanner, 'header')} property="og:description" />
          <meta content={get(banner, 'banner_image_url')} property="og:image" />
          <meta content={`https://${get(config, 'host')}`} property="og:url" />
          <meta content="website" property="og:type" />
        </Helmet>

        <Banner
          bgurl={get(banner, 'banner_image_url')}
          roundedborder={roundedBorder}
          size={bannerSize}
        >
          <BannerTabsHolder>
            <BannerTabs
              roundedBorder={roundedBorder}
              secondaryColor={secondaryColor}
              tabs={bannerTabs}
            />
          </BannerTabsHolder>
          <SearchContainer>
            <BannerTitleHolder>
              {this.renderBannerTitle(banner, primaryFont)}
            </BannerTitleHolder>
            <MerchantsSearch
              onNavigateTo={this.handleNavigationFromMerchantsSearch}
            />
          </SearchContainer>
        </Banner>

        <Content>
          <ResponsiveContent>
            <SubBanner
              bgurl={get(subBanner, 'banner_image_url')}
              roundedborder={roundedBorder}
            >
              <SubBannerContainer>
                <SubBannerTitle font={primaryFont}>
                  {get(subBanner, 'header')}
                </SubBannerTitle>
                <SubBannerText
                  anchor_color={secondaryColor}
                  dangerouslySetInnerHTML={{
                    __html: subBannerTitle,
                  }}
                />
              </SubBannerContainer>
              <SubBannerImageHolder roundedborder={roundedBorder}>
                <SubBannerImage src={get(subBanner, 'banner_image_url')} />
              </SubBannerImageHolder>
            </SubBanner>
          </ResponsiveContent>

          {canShowPopularCategories && (
            <Responsive py={32}>
              <FeaturedMerchantsContainer>
                <FeaturedMerchantsTitle font={primaryFont}>
                  {this.getSectionTitle(Labels.TITLE_POPULAR_CATEGORIES)}
                </FeaturedMerchantsTitle>
              </FeaturedMerchantsContainer>
              <FeaturedCategories
                categories={popularCategories}
                color={secondaryColor}
                marketplaceServiceType={marketplaceServiceType}
                roundedBorder={roundedBorder}
                showAllCategoriesPage={showAllCategoriesPage}
                sliderSettings={sliderSettings}
              />
              {showAllCategoriesPage && (
                <ButtonContainer>
                  <ExploreButton
                    color={secondaryColor}
                    hovercolor={secondaryTextColor}
                    primary={1}
                    roundedborder={roundedBorder}
                    onClick={this.handleAllCategoriesClick}
                  >
                    {_('common.allCategories')}
                  </ExploreButton>
                </ButtonContainer>
              )}
            </Responsive>
          )}

          {canShowPopularLocations && (
            <Responsive py={32}>
              <FeaturedMerchantsContainer>
                <FeaturedMerchantsTitle font={primaryFont}>
                  {this.getSectionTitle(Labels.TITLE_DESTINATIONS)}
                </FeaturedMerchantsTitle>
              </FeaturedMerchantsContainer>
              <FeaturedLocations
                color={secondaryColor}
                locations={featuredLocations}
                marketplaceServiceType={marketplaceServiceType}
                roundedBorder={roundedBorder}
                sliderSettings={sliderSettings}
                onLocationClick={this.handleNavigateLocation}
              />
            </Responsive>
          )}

          {canShowPopularSearches && (
            <Responsive py={32}>
              <FeaturedMerchantsContainer>
                <FeaturedMerchantsTitle font={primaryFont}>
                  {this.getSectionTitle(Labels.TITLE_POPULAR_SEARCHES)}
                </FeaturedMerchantsTitle>
              </FeaturedMerchantsContainer>
              <FeaturedCategories
                categories={popularSearches}
                circleBorder={50}
                color={secondaryColor}
                marketplaceServiceType={marketplaceServiceType}
                roundedBorder={roundedBorder}
                showAllCategoriesPage={showAllCategoriesPage}
                sliderSettings={sliderSettings}
              />
            </Responsive>
          )}

          {canShowTopOffers && showSliders && (
            <Responsive py={32}>
              <FeaturedMerchantsContainer>
                <FeaturedMerchantsTitle font={primaryFont}>
                  {this.getSectionTitle(Labels.TITLE_TOP_OFFERS)}
                </FeaturedMerchantsTitle>
              </FeaturedMerchantsContainer>

              {isFeaturedMerchantsLoading && (
                <LoaderHolder>
                  <Loader color={secondaryColor} />
                </LoaderHolder>
              )}

              <TopOffersSliders
                sliderSettings={sliderSettings}
                topOffers={topOffers}
              />

              {marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online && (
                <ButtonContainer>
                  <ExploreButton
                    color={secondaryColor}
                    hovercolor={secondaryTextColor}
                    primary={1}
                    roundedborder={roundedBorder}
                    onClick={this.handleExploreMerchantsClick}
                  >
                    {_('common.showMore')}
                  </ExploreButton>
                </ButtonContainer>
              )}
            </Responsive>
          )}

          {canShowFeaturedBusinesses && showSliders && (
            <Responsive py={32}>
              <FeaturedMerchantsContainer>
                <FeaturedMerchantsTitle font={primaryFont}>
                  {this.getSectionTitle(Labels.TITLE_FEATURED_BUSINESSES)}
                </FeaturedMerchantsTitle>
              </FeaturedMerchantsContainer>

              {isFeaturedMerchantsLoading && (
                <LoaderHolder>
                  <Loader color={secondaryColor} />
                </LoaderHolder>
              )}

              <FeaturedMerchantsSliders
                featuredBusinesses={featuredBusinesses}
                sliderSettings={sliderSettings}
                topOffers={topOffers}
              />

              {marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online && (
                <ButtonContainer>
                  <ExploreButton
                    color={secondaryColor}
                    hovercolor={secondaryTextColor}
                    primary={1}
                    roundedborder={roundedBorder}
                    onClick={this.handleExploreMerchantsClick}
                  >
                    {_('common.showMore')}
                  </ExploreButton>
                </ButtonContainer>
              )}
            </Responsive>
          )}

          {iframeUrl && (
            <Responsive mb={48} mt={24}>
              <FeaturedMerchantsTitle font={primaryFont} textAlign="left">
                {this.getSectionTitle(
                  Labels.TITLE_FEATURED_ARTICLES,
                  'Featured articles',
                )}
              </FeaturedMerchantsTitle>
              <IFrame src={iframeUrl} />
            </Responsive>
          )}
        </Content>
      </Container>
    )
  }
}

Home.defaultProps = {
  featuredLocations: [],
}

Home.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  featuredCategories: PropTypes.array.isRequired,
  featuredLocations: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  featuredMerchants: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  isFeaturedCategoriesLoading: PropTypes.bool.isRequired,
  isFeaturedLocationsLoading: PropTypes.bool.isRequired,
  isFeaturedMerchantsLoading: PropTypes.bool.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
}

export default withAppContext(Home)
